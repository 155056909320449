import React, { Component } from 'react';
import { Link } from 'gatsby';
// import logo from "../assets/images/logo-dark.png";
import logo from '../assets/images/luth-logo.png';
class NavOne extends Component {
  constructor() {
    super();
    this.state = {
      sticky: false,
    };
  }
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);

    //Mobile Menu
    this.mobileMenu();

    //Search Toggle
    // this.serachButton();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    if (window.scrollY > 70) {
      this.setState({
        sticky: true,
      });
    } else if (window.scrollY < 70) {
      this.setState({
        sticky: false,
      });
    }
  };

  mobileMenu = () => {
    //Mobile Menu Toggle
    let mainNavToggler = document.querySelector('.menu-toggler');
    let mainNav = document.querySelector('.main-navigation');

    mainNavToggler.addEventListener('click', function () {
      mainNav.style.display =
        mainNav.style.display !== 'block' ? 'block' : 'none';
    });
  };

  // serachButton = () => {
  //   let searchToggle = document.querySelector('.search-toggle');
  //   let searchPopup = document.querySelector('.search-popup');
  //   let searchClose = document.querySelector('.cancel');
  //   let searchOverlay = document.querySelector('.search-overlay');

  //   searchToggle.addEventListener('click', function () {
  //     searchPopup.classList.add('active');
  //   });

  //   searchClose.addEventListener('click', function () {
  //     searchPopup.classList.remove('active');
  //   });

  //   searchOverlay.addEventListener('click', function () {
  //     searchPopup.classList.remove('active');
  //   });
  // };

  render() {
    return (
      <header className='site-header site-header__header-one '>
        <nav
          className={`navbar navbar-expand-lg navbar-light header-navigation stricky ${
            this.state.sticky ? 'stricked-menu stricky-fixed' : ''
          }`}
        >
          <div className='container clearfix'>
            <div className='logo-box clearfix'>
              <Link to='/' className='navbar-brand'>
                <img
                  src={logo}
                  className='main-logo'
                  height={this.state.sticky && '75'}
                  // width="128"
                  alt='Awesome alter text'
                />
              </Link>
              {/* <div className="header__social">
                <a href="#none">
                  <i className="fab fa-twitter"></i>
                </a>
                <a href="#none">
                  <i className="fab fa-facebook-square"></i>
                </a>
                <a href="#none">
                  <i className="fab fa-pinterest-p"></i>
                </a>
                <a href="#none">
                  <i className="fab fa-instagram"></i>
                </a>
              </div> */}
              <button className='menu-toggler'>
                <span className='kipso-icon-menu'></span>
              </button>
            </div>
            <div className='main-navigation'>
              <ul className=' navigation-box'>
                <li className='current'>
                  <Link to='/'>Home</Link>
                </li>
                <li>
                  <a href='#none'>About</a>
                  <ul className='sub-menu'>
                    <li>
                      <Link to='/about-us/'>About Us</Link>
                    </li>
                    <li>
                      <Link to='/about-us/history'>History</Link>
                    </li>
                    <li>
                      <Link to='/about-us/our-structure'>Structure</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href='#none'>Departments</a>
                  <ul className='sub-menu'>
                    <li>
                      <Link to='/departments'>Clinical Departments</Link>
                    </li>
                    <li>
                      <Link to='/allied-clinical-department'>
                        Allied Clinical Departments
                      </Link>
                    </li>
                    <li>
                      <Link to='/non-clinical-departments'>
                        Non-Clinical Departments
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href='#none'>Patient Guide</a>
                  <ul className='sub-menu'>
                    <li>
                      <Link to='/patient-guide/clinics'>
                        Clinics & Clinic Days
                      </Link>
                    </li>
                    <li>
                      <Link to='/patient-guide/health-info'>
                        Health Information
                      </Link>
                    </li>
                    <li>
                      <Link to='/patient-guide/patient-testimonials'>
                        Patient Testimonials
                      </Link>
                    </li>
                    <li>
                      {/* <Link to='/patient-guide/complaints-servicom'>
                        Complaints - Servicom
                      </Link> */}
                      <a href='#none'>Complaints - Servicom</a>
                      <ul className='sub-menu'>
                        <li>
                          <Link to='/patient-guide/complaints-servicom'>
                            Contact Us
                          </Link>
                        </li>
                        <li>
                          <Link to='/patient-guide/complaints-servicom-charter'>
                            Our Charter
                          </Link>
                        </li>
                        <li>
                          <Link to='/patient-guide/ClientAccess'>
                            Client's Access
                          </Link>
                        </li>
                        {/* <li>
                      <Link to='#'>
                        Client's Access
                      </Link>
                    </li> */}
                      </ul>
                    </li>
                    {/* <li>
                      <Link to='/'>Diseases & Conditions</Link>
                    </li>
                    <li>
                      <Link to='/'>Symptons A-Z</Link>
                    </li> */}
                  </ul>
                </li>
                <li>
                  <a href='#none'>News & Events</a>
                  <ul className='sub-menu'>
                    <li>
                      <Link to='/newsarchives2024'>2025 Latest News</Link>
                    </li>
                    <li>
                      <Link to='/videos'>Videos</Link>
                    </li>
                    <li>
                      <Link to='/events'>Event Archives</Link>
                    </li>
                    <li>
                      <Link to='/newsarchives'>News Archives</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href='#none'>Research & Training</a>
                  <ul className='sub-menu'>
                    <li>
                      <Link to='/past-research'>Past Research</Link>
                    </li>
                    <li>
                      <Link to='/grants'>Research Grants</Link>
                    </li>
                    <li>
                      <Link to='/'>Ethics</Link>
                    </li>
                    <li>
                      <Link to='/'>Collaboration</Link>
                    </li>
                    <li>
                      <Link to='/schools'>LUTH Schools</Link>
                    </li>
                    <li>
                      <Link to='/'>Scientific Conferences </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href='#none'>Policy</a>
                  <ul className='sub-menu'>
                    <li>
                      <Link to='/past-research'>Antibiotic Policy</Link>
                    </li>
                    {/* <li>
                      <Link to='/grants'>Research Grants</Link>
                    </li>
                    <li>
                      <Link to='/'>Ethics</Link>
                    </li>
                    <li>
                      <Link to='/'>Collaboration</Link>
                    </li>
                    <li>
                      <Link to='/schools'>LUTH Schools</Link>
                    </li>
                    <li>
                      <Link to='/'>Scientific Conferences </Link>
                    </li> */}
                  </ul>
                </li>
                <li>
                  <Link to='/schools'>Schools</Link>
                </li>
                <li>
                  <Link to='/contact'>Contact</Link>
                </li>
              </ul>
            </div>
            {/* <div className='right-side-box'>
              <a
                className='header__search-btn search-popup__toggler search-toggle'
                href='#none'
              >
                <i className='kipso-icon-magnifying-glass'></i>
              </a>
            </div> */}
          </div>
        </nav>
        <div className='site-header__decor'>
          <div className='site-header__decor-row'>
            <div className='site-header__decor-single'>
              <div className='site-header__decor-inner-1'></div>
            </div>
            <div className='site-header__decor-single'>
              <div className='site-header__decor-inner-2'></div>
            </div>
            <div className='site-header__decor-single'>
              <div className='site-header__decor-inner-3'></div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default NavOne;
